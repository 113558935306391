import PrivateLayout from "./layouts/PrivateLayout";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import PublicLayout from "./layouts/PublicLayout";
import { userSelector } from "./selectors/userSelectors";
import { isNotEmpty } from "ramda-extension";

export const PrivateRoute = ({ children, authorities }) => {
  const user = useSelector(userSelector);

  return isNotEmpty(user) &&
    user.authorities.some((prvek) => authorities.includes(prvek)) ? (
    <PrivateLayout>{children}</PrivateLayout>
  ) : (
    <PublicLayout />
  );
};

PrivateRoute.propTypes = {
  children: PropTypes.node.isRequired,
};
