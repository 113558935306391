import React, { useState } from "react";
import PropTypes from "prop-types";
import FormFieldWrapper from "../FormFieldWrapper";

const PasswordInput = (prop) => {
  const { input, title, autoFocus = false } = prop;

  const [typeState, setTypeState] = useState("password");

  return (
    <FormFieldWrapper name={input.name} {...prop}>
      <input
        {...input}
        id={input.name}
        title={title}
        type={typeState}
        autoFocus={autoFocus}
      />
      <i
        onClick={() =>
          setTypeState(typeState === "password" ? "text" : "password")
        }
        className={typeState === "password" ? "icon-eye-blocked" : "icon-eye"}
      ></i>
    </FormFieldWrapper>
  );
};

PasswordInput.propTypes = {
  input: PropTypes.object,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default PasswordInput;
