import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

export const useInit = (promise) => {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!promise) return;
    if (!initialized) {
      dispatch(promise).then(() => setInitialized(true));
    }
  }, [initialized, promise]);
  return initialized;
};
