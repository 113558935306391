import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { dataPath } from "../constants";
import LoginForm from "../forms/LoginForm";
import Container from "./ui/blocks/Container";
import CenterLayout from "./ui/layouts/CenterLayout";
import CoverLayout from "./ui/layouts/CoverLayout";
import StackLayout from "./ui/layouts/StackLayout";

const LoginPage = (props) => {
  const { t } = useTranslation();
  return (
    <div className="-Page-body">
      <CoverLayout>
        <div>
          <Container width="6">
            <div>
              <StackLayout space="8">
                <CenterLayout>
                  <Link title={t("labels.logoDescription")} to="/">
                    <img
                      className="-Logo"
                      src={`${dataPath}/m2-robotics.svg`}
                      alt={t("labels.logoDescription")}
                    />
                  </Link>
                </CenterLayout>
                <StackLayout space="5">
                  <StackLayout space="2">
                    <div>
                      <h1 className="h1">{t("labels.login")}</h1>
                    </div>
                    <div>
                      <div>{t("labels.loginDescription")}</div>
                    </div>
                  </StackLayout>
                  <LoginForm />
                </StackLayout>
              </StackLayout>
            </div>
          </Container>
        </div>
      </CoverLayout>
    </div>
  );
};

export default LoginPage;
