import { handleActions } from "redux-actions";
import { modalTypes } from "../constants";

const initState = {};

const open = {
  next(state, action) {
        return { ...state, [action.payload.name]: true };
  },
};

const close = {
    next(state, action) {
        return { ...state, [action.payload.name]: false };
  },
};

const alertsReducer = {
  [modalTypes.OPEN]: open,
  [modalTypes.CLOSE]: close,
};

export default handleActions(alertsReducer, initState);
