import axios from "axios";
import {apiUrl, PAGES_PATHS} from "../constants";
import { returnPath } from "../utils";

const {get} = axios;

const kubernetesBase = `${apiUrl}${returnPath(PAGES_PATHS.kubernetes)}`;
export const loadKubernetesComponentsStatus = (header) => get(`${kubernetesBase}/status`, header);
export const loadKubernetesDeployments = (header) => get(`${kubernetesBase}/deployments`, header);
export const loadKubernetesOrphans = (header) => get(`${kubernetesBase}/orphans`, header);
export const loadKubernetesImplementation = (header) => get(`${kubernetesBase}/implementation`, header);