import { pickBy } from 'ramda';
import { handleActions } from 'redux-actions';
import { datasourceTypes } from "../constants";

const initState = {};

const set = {
    next(state, action) {
        return { ...state, [action.payload.name]: action.payload.data };
    },
};

const clear = {
    next(state, action) {
        return pickBy((v, k) => k !== action.payload.name, state);
    },
};

const alertsReducer = {
    [datasourceTypes.SET]: set,
    [datasourceTypes.CLEAR]: clear,
};

export default handleActions(alertsReducer, initState);