import { dataSourceNames, modalNames } from "../constants";
import { alarmResources } from "../resources";
import { header } from "../utils";
import { setDataSource } from "./dataSourceActions";
import { closeModal } from "./modalActions";

export const getAlarm =
  ({ alarmId }) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      alarmResources
        .getAlarm({ alarmId }, header(getState))
        .then((alarm) => {
          dispatch(setDataSource(dataSourceNames.LAST_ALARM, alarm.data));
          resolve();
        })
        .catch(reject);
    });
  };

export const sendAlarmComment =
  ({ alarmId, text }) =>
  (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      alarmResources
        .sendComment({ alarmId, text }, header(getState))
        .then((alarm) => {
          // dispatch(setDataSource(dataSourceNames.LAST_ALARM, alarm.data));
          dispatch(closeModal(modalNames.ALARM_COMMENT_MODAL));

          resolve();
        })
        .catch(reject);
    });
  };

export const loadAlarms = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    alarmResources
      .loadAlarms(header(getState))
      .then((alarms) => {
        dispatch(setDataSource(dataSourceNames.ALARMS, alarms.data));
        resolve();
      })
      .catch(reject);
  });
};
