import { map, chain, type, toPairs, fromPairs } from "ramda";
import { dataSourceNames } from "./constants";
import { getDataSource } from "./selectors/datasource";

export const flattenObject = (obj) => {
  const go = (obj_) =>
    chain(([k, v]) => {
      if (type(v) === "Object" || type(v) === "Array") {
        return map(([k_, v_]) => [`${k}.${k_}`, v_], go(v));
      } else {
        return [[k, v]];
      }
    }, toPairs(obj_));

  return fromPairs(go(obj));
};

export const childElement = (children) => <div>{children}</div>;

export const header = (getState) => ({
  auth: getDataSource(dataSourceNames.ACCESS_CREDENTIALS)(getState()),
});

export const returnPath = (pathElement) => pathElement.path;

export const formatCityAndZip = (city, zip) => `${city}, ${zip}`;
