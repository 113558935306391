import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "./ui/blocks/Container";
import StackLayout from "./ui/layouts/StackLayout";
import ClusterLayout from "./ui/layouts/ClusterLayout";
import { Link } from "react-router-dom";
import { dataSourceNames, PAGES_PATHS } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { loadUsers } from "../actions/userActions";
import { getDataSource } from "../selectors/datasource";
import { join } from "ramda";
import { returnPath } from "../utils";

const Users = (props) => {
  const { t } = useTranslation();

  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  const users = useSelector(getDataSource(dataSourceNames.USERS));

  useEffect(() => {
    if (!initialized) {
      dispatch(loadUsers()).then(() => setInitialized(true));
    }
  }, [initialized]);

  return (
    <Container>
      <StackLayout space="2">
        <ClusterLayout justify="space-between" align="center">
          <div>
            <h1 className="-Heading" data-variant="h1">
              {t(`labels.users`)}
            </h1>
          </div>
          <div>
            <Link
              to={returnPath(PAGES_PATHS.addUser)}
              className="-Button"
              data-type="icon"
            >
              <i className="icon-user-plus"></i>
            </Link>
          </div>
        </ClusterLayout>
        {initialized && (
          <div>
            <table className="-Table">
              <thead>
                <tr>
                  <th>{t(`labels.id`)}</th>
                  <th>{t(`labels.userName`)}</th>
                  <th>{t(`labels.state`)}</th>
                  <th>{t(`labels.role`)}</th>
                  <th>{t(`labels.customer`)}</th>
                  <th className="-widthZero"></th>
                </tr>
              </thead>
              <tbody>
                {users.map((value, index) => (
                  <tr key={index}>
                    <td>{value.userId}</td>
                    <td>{value.userName}</td>
                    <td>
                      {value.enabled
                        ? t(`enums.userStatus.ok`)
                        : t(`enums.userStatus.banned`)}
                    </td>
                    <td>
                      {join(
                        ", ",
                        value.authorities.map((role) =>
                          t(`enums.roles.${role}`)
                        )
                      )}
                    </td>
                    <td>Zákazník</td>
                    <td className="-widthZero">
                      <Link
                        to={`${returnPath(PAGES_PATHS.addUser)}/${
                          value.userId
                        }`}
                        className="-Button"
                        data-type="icon"
                      >
                        <i className="icon-pencil"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </StackLayout>
    </Container>
  );
};

export default Users;
