import { find, isEmpty, propEq } from "ramda";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, initialize } from "redux-form";
import {
  addOrUpdateUser,
  loadUsers,
  transformUserToLocaleStorage,
} from "../actions/userActions";
import { withReduxForm } from "../components/hocs/withReduxForm";
import StackLayout from "../components/ui/layouts/StackLayout";
import { dataSourceNames, FORMS, ROLES, USERS_FORM_FIELDS } from "../constants";
import { getDataSource } from "../selectors/datasource";
import { required } from "../validations";
import TextInput from "./inputs/TextInput";
import PasswordInput from "./inputs/PasswordInput";
import SelectInput from "./inputs/SelectInput";
import { userFormInitialValues } from "../selectors/userSelectors";
import { loadCustomers } from "../actions/customerActions";
import { customersSelector } from "../selectors/customerSelectors";

const UserForm = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const users = useSelector(getDataSource(dataSourceNames.USERS));
  const customers = useSelector(customersSelector);
  const dispatch = useDispatch();
  const [initializedCustomers, setInitializedCustomer] = useState(
    customers !== undefined
  );
  const [initializedUsers, setInitializedUsers] = useState(!isEmpty(users));

  useEffect(() => {
    if (!initializedCustomers) {
      dispatch(loadCustomers()).then(() => setInitializedCustomer(true));
    }
    if (!initializedUsers) {
      dispatch(loadUsers()).then(() => setInitializedUsers(true));
    }
  }, [initializedUsers]);

  useEffect(() => {
    initializedUsers &&
      dispatch(
        initialize(FORMS.userForm, find(propEq("userId", Number(id)))(users))
      );
  }, [id, users, initializedUsers]);

  return (
    <Fragment>
      {initializedCustomers && initializedUsers && (
        <form className="-FormControl" onSubmit={handleSubmit}>
          <StackLayout space="2">
            <Field
              name={USERS_FORM_FIELDS.userName}
              component={TextInput}
              label={t("labels.userName")}
              title={t("labels.userName")}
              validate={[required]}
            />
            <Field
              name={USERS_FORM_FIELDS.password}
              component={PasswordInput}
              label={t("labels.password")}
              title={t("labels.password")}
              validate={[required]}
            />
            <Field
              name={USERS_FORM_FIELDS.passwordControl}
              component={PasswordInput}
              label={t("labels.passwordControl")}
              title={t("labels.passwordControl")}
              validate={[required]}
            />
            <Field
              name={USERS_FORM_FIELDS.authorities}
              component={SelectInput}
              multiple
              inputValues={Object.values(ROLES).map((role) => ({
                label: t(`enums.roles.${role}`),
                value: role,
              }))}
              label={t("labels.role")}
              title={t("labels.role")}
              validate={[required]}
            />
            <Field
              name={USERS_FORM_FIELDS.belongsToCustomerId}
              component={SelectInput}
              multiple
              inputValues={[
                { label: "", value: null },
                ...customers.map((customer) => ({
                  label: customer.name,
                  value: customer.id,
                })),
              ]}
              validate={[required]}
              label={t("labels.customer")}
              title={t("labels.customer")}
            />
            <div>
              <button
                className="-Button"
                title={t("labels.login")}
                type="submit"
              >
                {t("labels.save")}
              </button>
            </div>
          </StackLayout>
        </form>
      )}
    </Fragment>
  );
};

export default withReduxForm(UserForm, {
  submitAction: addOrUpdateUser,
  initialValues: userFormInitialValues,
  form: FORMS.userForm,
});
