import PropTypes from "prop-types";

const CoverLayout = ({ space, children, className = "", ...rest }) => {
  return (
    <div
      className={`-coverLayout ${className}`}
      data-space={space ? space : undefined}
      {...rest}
    >
      {children}
    </div>
  );
};

CoverLayout.propTypes = {
  space: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CoverLayout;
