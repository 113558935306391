import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  dataPath,
  dataSourceNames,
  PAGES_PATHS,
  NAV_ITEMS,
} from "../constants";
import StackLayout from "./ui/layouts/StackLayout";
import BoxLayout from "./ui/layouts/BoxLayout";
import { Link, NavLink, useNavigate } from "react-router-dom";
import ClusterLayout from "../components/ui/layouts/ClusterLayout";
import { useDispatch, useSelector } from "react-redux";
import { setDataSource } from "../actions/dataSourceActions";
import { batchActions } from "redux-batched-actions";
import { toast } from "react-toastify";
import { userSelector } from "../selectors/userSelectors";
import { returnPath } from "../utils";

const MainNav = () => {
  const [expanded, setExpanded] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const user = useSelector(userSelector);

  const logOut = useCallback(() => {
    dispatch(
      batchActions([
        setDataSource(dataSourceNames.USER, {}),
        setDataSource(dataSourceNames.ACCESS_CREDENTIALS, {}),
        setDataSource(dataSourceNames.USERS, {}),
      ])
    );
    toast.success(t("labels.logedOut"));
    navigate(returnPath(PAGES_PATHS.home));
  }, []);

  const printLink = (data, index) => (
    <li key={index} className="-Nav-item">
      <div className="-Nav-itemContents">
        <NavLink className="-Nav-link" to={returnPath(data)}>
          <span className="-Nav-linkContents">
            <span className="-Nav-linkContent">
              <span
                className="-clusterLayout"
                data-space="0"
                data-nowrap="true"
                data-align="center"
              >
                <span>
                  <span className="-Nav-icon">
                    <i className={`icon-${data.icon}`} />
                  </span>
                  <span className="-Nav-label">
                    {t(`mainNavItems.${data.label}`)}
                  </span>
                </span>
              </span>
            </span>
          </span>
        </NavLink>
      </div>
    </li>
  );

  return (
    <div className="-Page-header">
      <div className="-Nav" data-variant="main" data-expanded={expanded}>
        <div>
          <StackLayout space="0">
            <div className="-Nav-header">
              <ClusterLayout
                noWrap
                justify="space-between"
                align="center"
                space="3"
              >
                <div
                  onClick={() => setExpanded(!expanded)}
                  data-expanded={expanded}
                  className="-Burger"
                  role="button"
                >
                  <i className="-Burger-icon"></i>
                  <span className="-visuallyHidden">Menu</span>
                </div>
                <div className="-Nav-logo">
                  <Link title={t("labels.logoDescription")} to="/">
                    <img
                      className="-Logo"
                      src={`${dataPath}/m2-robotics.svg`}
                      alt={t("labels.logoDescription")}
                    />
                  </Link>
                </div>
              </ClusterLayout>
            </div>
            <div className="-Nav-body">
              <ul className="-stackLayout -Nav-list" data-space="2">
                {NAV_ITEMS.map(
                  (item, index) =>
                    user.authorities.some((prvek) =>
                      item.authorities.includes(prvek)
                    ) && (
                      <StackLayout key={index}>
                        <li
                          key={index}
                          className="-Nav-item"
                          data-variant="heading"
                        >
                          <span
                            className="-clusterLayout"
                            data-space="0"
                            data-nowrap="true"
                            data-align="center"
                          >
                            <span>
                              <span className="-Nav-icon">
                                <i className={`icon-${item.icon}`} />
                              </span>
                              <span className="-Nav-label">
                                {t(`mainNavItems.${item.label}`)}
                              </span>
                            </span>
                          </span>
                        </li>
                        {item.children.map((child, index) =>
                          printLink(child, index)
                        )}
                      </StackLayout>
                    )
                )}
              </ul>
            </div>
          </StackLayout>
          <div>
            <BoxLayout space="01">
              <button className="-Button" onClick={logOut}>
                {expanded ? t("labels.logOut") : <>&#xea14;</>}
              </button>
            </BoxLayout>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav;
