import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import GridLayout from "./ui/layouts/GridLayout";

const CameraOverview = (props) => {
  const { t } = useTranslation();

  return (
    <GridLayout fragment="1of3">
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
      <div>
        <img className="-widthStratch" src="http://placekitten.com/1920/1080" />
      </div>
    </GridLayout>
  );
};

export default CameraOverview;
