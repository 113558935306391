import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import dataSourceReducer from "./dataSourceReducer";
import modalReducer from "./modalReducer";

export const rootReducer = combineReducers({
    // common
    form: formReducer,
    // routing: routerReducer,
    modal: modalReducer,
    // app
    // routerReducer,
    // alerts: alertsReducer,
    // loading: loadingReducer,
    dataSource: dataSourceReducer,
});