import axios from "axios";
import { apiUrl, LOGIN_FORM_FIELDS } from "../constants";

const { get, post, patch } = axios;

export const login = (loginRequest) =>
  get(`${apiUrl}/users/me`, {
    auth: {
      username: loginRequest[LOGIN_FORM_FIELDS.userName],
      password: loginRequest[LOGIN_FORM_FIELDS.password],
    },
  });

export const loadUsers = (header) => get(`${apiUrl}/users`, header);

export const loadMe = (header) => get(`${apiUrl}/users/me`, header);

export const addUser = (data, header) => post(`${apiUrl}/users`, data, header);

export const updateUser = ({ userId, ...data }, header) =>
  patch(`${apiUrl}/users/${userId}`, data, header);
