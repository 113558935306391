import { useTranslation } from "react-i18next";
import Container from "./ui/blocks/Container";
import StackLayout from "./ui/layouts/StackLayout";
import ClusterLayout from "./ui/layouts/ClusterLayout";
import { PAGES_PATHS, dataSourceNames } from "../constants";
import { useSelector } from "react-redux";
import { getDataSource } from "../selectors/datasource";
import { useInit } from "../hooks";
import { isNotEmpty } from "ramda-extension";
import { loadCustomers } from "../actions/customerActions";
import { formatCityAndZip, returnPath } from "../utils";
import { Link } from "react-router-dom";

const Customers = () => {
  const { t } = useTranslation();

  const customers = useSelector(getDataSource(dataSourceNames.CUSTOMERS));

  const initialized = useInit(loadCustomers());

  return (
    <Container>
      <StackLayout space="2">
        <ClusterLayout justify="space-between" align="center">
          <div>
            <h1 className="-Heading" data-variant="h1">
              {t(`labels.customers`)}
            </h1>
          </div>
          <div>
            <Link
              to={returnPath(PAGES_PATHS.addCustomer)}
              className="-Button"
              data-type="icon"
            >
              <i className="icon-plus"></i>
            </Link>
          </div>
        </ClusterLayout>
        {(initialized || isNotEmpty(customers)) && (
          <div>
            <table className="-Table">
              <thead>
                <tr>
                  <th>{t(`labels.name`)}</th>
                  <th>{t(`labels.ico`)}</th>
                  <th>{t(`labels.adress`)}</th>
                  <th>{t(`labels.contactPerson`)}</th>
                  <th>{t(`labels.discSizeLimit`)}</th>
                  <th>{t(`labels.limitRecordsPerDay`)}</th>
                  <th className="-widthZero"></th>
                </tr>
              </thead>
              <tbody>
                {customers.map((value, index) => (
                  <tr key={index}>
                    <td>{value.name}</td>
                    <td>{value.ico}</td>
                    <td>
                      <StackLayout>
                        <span>{value.street}</span>
                        <span>
                          {formatCityAndZip(value.city, value.postCode)}
                        </span>
                      </StackLayout>
                    </td>
                    <td>
                      <StackLayout>
                        <span>{value.contactPersonName}</span>
                        <span>{value.contactPersonPhone}</span>
                      </StackLayout>
                    </td>
                    <td>{value.discSizeLimit}</td>
                    <td>{value.limitRecordsPerDay}</td>
                    <td className="-widthZero">
                      <Link
                        to={`${returnPath(PAGES_PATHS.addCustomer)}/${
                          value.id
                        }`}
                        className="-Button"
                        data-type="icon"
                      >
                        <i className="icon-pencil"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </StackLayout>
    </Container>
  );
};

export default Customers;
