import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./ui/blocks/Container";
import StackLayout from "./ui/layouts/StackLayout";
import ClusterLayout from "./ui/layouts/ClusterLayout";
import YoloClassesForm from "../forms/YoloClassesForm";

const YoloClasses = (props) => {
  const { t } = useTranslation();

  return (
    <Container>
      <StackLayout space="2">
        <ClusterLayout justify="space-between" align="center">
          <div>
            <h1 className="-Heading" data-variant="h1">
              {t(`labels.yoloClasses`)}
            </h1>
          </div>
          <div></div>
        </ClusterLayout>
        <YoloClassesForm />
      </StackLayout>
    </Container>
  );
};

export default YoloClasses;
