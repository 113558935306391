import LoginPage from "../components/LoginPage";

function PublicLayout() {
  return (
    <div>
      <LoginPage />
    </div>
  );
}

export default PublicLayout;
