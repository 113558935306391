import { toast } from "react-toastify";
import { dataSourceNames, PAGES_PATHS } from "../constants";
import historyObject from "../history";
import i18n from "../i18n";
import { userResources } from "../resources";
import { header, returnPath } from "../utils";
import { setDataSource } from "./dataSourceActions";

export const login =
  (loginData = {}) =>
  (dispatch) => {
    return userResources
      .login(loginData)
      .then((body) => {
        dispatch(setDataSource(dataSourceNames.USER, body.data));
        dispatch(
          setDataSource(dataSourceNames.ACCESS_CREDENTIALS, {
            username: loginData.userName,
            password: loginData.password,
          })
        );
      })
      .catch((error) => {
        toast.error(i18n.t("labels.logedInError"));
      });
  };

export const loadUsers = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    userResources
      .loadUsers(header(getState))
      .then((users) => {
        dispatch(setDataSource(dataSourceNames.USERS, users.data));
        resolve();
      })
      .catch(reject);
  });
};

export const addOrUpdateUser = (userData) => (dispatch, getState) => {
  const newUserData = {
    ...userData,
    authorities: userData.authorities,
    enabled: true,
  };
  delete newUserData.passwordControl;

  const resource = userData.userId
    ? userResources.updateUser
    : userResources.addUser;

  return new Promise((resolve, reject) => {
    resource(newUserData, header(getState))
      .then((response) => {
        toast.success(i18n.t("labels.saved"));
        historyObject.push(returnPath(PAGES_PATHS.users));
        resolve();
      })
      .catch(reject);
  });
};
