import PropTypes from "prop-types";
import { childElement } from "../../../utils";


const GridLayout = ({
  overflowVisible,
  noWrap,
  align,
  justify,
  reverseDirectionMd,
  equalizeContentsHeight,
  fragment,
  contentAlignStretch,
  fragmentSm,
  fragmentMd,
  fragmentLg,
  fragmentMinWidth,
  space,
  className = "",
  children,
  ...rest
}) => {
  return (
    <div
      className={`-gridLayout ${className}`}
      data-overflowvisible={overflowVisible ? "true" : undefined}
      data-nowrap={noWrap ? "true" : undefined}
      data-align={align ? align : undefined}
      data-justify={justify ? justify : undefined}
      data-reversedirection-md={reverseDirectionMd ? "true" : undefined}
      data-equalizecontentsheight={equalizeContentsHeight ? "true" : undefined}
      data-fragment={fragment ? fragment : undefined}
      data-contentalign={contentAlignStretch ? "stretch" : undefined}
      data-fragment-sm={fragmentSm ? fragmentSm : undefined}
      data-fragment-mm={fragmentMd ? fragmentMd : undefined}
      data-fragment-lg={fragmentLg ? fragmentLg : undefined}
      data-fragmentminmidth={fragmentMinWidth ? fragmentMinWidth : undefined}
      data-space={space ? space : undefined}
      {...rest}
    >
      {children.length === 1 ? children : childElement(children)}
    </div>
  );
};

GridLayout.propTypes = {
  overflowVisible: PropTypes.bool,
  noWrap: PropTypes.bool,
  align: PropTypes.string,
  justify: PropTypes.string,
  reverseDirectionMd: PropTypes.bool,
  equalizeContentsHeight: PropTypes.bool,
  fragment: PropTypes.string,
  contentAlignStretch: PropTypes.bool,
  fragmentSm: PropTypes.string,
  fragmentMd: PropTypes.string,
  fragmentLg: PropTypes.string,
  fragmentMinWidth: PropTypes.string,
  space: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default GridLayout;
