import { reduxForm } from "redux-form";

export const withReduxForm = (
  component,
  {
    form,
    submitAction = (fromValues) => console.log(fromValues),
    destroyOnUnmount = true,
    initialValues = () => ({}),
  }
) => {
  const onSubmit = ((submitAction) => (values, dispatch) => {
    let formState = values;
    dispatch(submitAction(formState));
  })(submitAction);

  return reduxForm({
    form,
    destroyOnUnmount,
    onSubmit,
    initialValues: initialValues(),
  })(component);
};
