import { Route, Routes } from "react-router-dom";
import AddCamera from "../components/AddCamera";
import AddUser from "../components/AddUser";
import CameraOverview from "../components/CameraOverview";
import Cameras from "../components/Cameras";
import NotFound from "../components/NotFound";
import Users from "../components/Users";
import { PAGES_PATHS, ROLES } from "../constants";
import { PrivateRoute } from "../middlewares";
import YoloClasses from "../components/YoloClasses";
import Kubernetes from "../components/Kubernetes";
import { returnPath } from "../utils";
import Alarms from "../components/Alarms";
import Companies from "../components/Companies";
import Customers from "../components/Customers";
import AddCustomer from "../components/AddCustomer";
import AddCompany from "../components/AddCompany";

function AppLayout() {
  return (
    <div className="-Page">
      <Routes>
        <Route
          exact
          index
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_WEB]}>
              <CameraOverview />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={returnPath(PAGES_PATHS.users)}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_WEB]}>
              <Users />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.addUser)}/:id?`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN]}>
              <AddUser />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.cameras)}`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN]}>
              <Cameras />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.addCamera)}`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN]}>
              <AddCamera />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.addCamera)}/:id?`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN]}>
              <AddCamera />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.yoloClasses)}`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN]}>
              <YoloClasses />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.kubernetes)}`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN]}>
              <Kubernetes />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.alarms)}`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_WEB]}>
              <Alarms />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.companies)}`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_WEB]}>
              <Companies />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.addCompany)}/:id?`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_WEB]}>
              <AddCompany />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.customers)}`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_WEB]}>
              <Customers />
            </PrivateRoute>
          }
        />
        <Route
          exact
          path={`${returnPath(PAGES_PATHS.addCustomer)}/:id?`}
          element={
            <PrivateRoute authorities={[ROLES.ROLE_ADMIN, ROLES.ROLE_WEB]}>
              <AddCustomer />
            </PrivateRoute>
          }
        />
        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default AppLayout;
