import Reactfrom from "react";
import { useTranslation } from "react-i18next";
import Container from "./ui/blocks/Container";
import StackLayout from "./ui/layouts/StackLayout";
import ClusterLayout from "./ui/layouts/ClusterLayout";
import { dataSourceNames } from "../constants";
import { useSelector } from "react-redux";
import { getDataSource } from "../selectors/datasource";
import { loadAlarms } from "../actions/alarmActions";
import { useInit } from "../hooks";
import { isNotEmpty } from "ramda-extension";

const Alarms = () => {
  const { t } = useTranslation();

  const alarms = useSelector(getDataSource(dataSourceNames.ALARMS));

  const initialized = useInit(loadAlarms());

  return (
    <Container>
      <StackLayout space="2">
        <ClusterLayout justify="space-between" align="center">
          <div>
            <h1 className="-Heading" data-variant="h1">
              {t(`labels.alarms`)}
            </h1>
          </div>
          <div></div>
        </ClusterLayout>
        {(initialized || isNotEmpty(alarms)) && (
          <div>
            <table className="-Table">
              <thead>
                <tr>
                  <th>{t(`labels.id`)}</th>
                  <th>{t(`labels.alarmState`)}</th>
                  <th>{t(`labels.alarmType`)}</th>
                  <th>{t(`labels.dateAdd`)}</th>
                  <th>{t(`labels.dateAdateLastEditdd`)}</th>
                  <th className="-widthZero"></th>
                </tr>
              </thead>
              <tbody>
                {alarms.map((value, index) => (
                  <tr key={index}>
                    <td>{value.id}</td>
                    <td>{value.alarmState}</td>
                    <td>{value.alarmType}</td>
                    <td>{value.dateAdd}</td>
                    <td>{value.dateLastEdit}</td>
                    <td className="-widthZero">
                      <a
                        target="_blank"
                        href={value.recordPath}
                        className="-Button"
                        data-type="icon"
                      >
                        <i className="icon-download"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </StackLayout>
    </Container>
  );
};

export default Alarms;
