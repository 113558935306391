import { find, isEmpty, map, pipe, propEq, reverse, toPairs } from "ramda";
import { isNotEmpty } from "ramda-extension";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { batchActions } from "redux-batched-actions";
import { Field, initialize } from "redux-form";
import {
  addOrUpdateCamera,
  loadCameras,
  transformCameraToLocaleStorage,
} from "../actions/cameraActions";
import { loadCustomers } from "../actions/customerActions";
import { withReduxForm } from "../components/hocs/withReduxForm";
import ClusterLayout from "../components/ui/layouts/ClusterLayout";
import StackLayout from "../components/ui/layouts/StackLayout";
import { CAMERAS_FORM_FIELDS, dataSourceNames, FORMS } from "../constants";
import {
  cameraConfigSelector,
  cameraFormInitialValues,
} from "../selectors/cameraSelectors";
import { customersSelector } from "../selectors/customerSelectors";
import { getDataSource } from "../selectors/datasource";
import { cameraConfigDuplicityKeyControl, required } from "../validations";
import CheckBox from "./inputs/CheckBox";
import SelectInput from "./inputs/SelectInput";
import TextInput from "./inputs/TextInput";
import { arrayPush } from "redux-form";
import { arrayPop } from "redux-form";

const CameraForm = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const { id } = useParams();
  const cameras = useSelector(getDataSource(dataSourceNames.CAMERAS));
  const customers = useSelector(customersSelector);
  const dispatch = useDispatch();

  const cameraConfig = useSelector(cameraConfigSelector);

  const [initializedCustomers, setInitializedCustomer] = useState(
    customers !== undefined
  );
  const [initializedCameras, setInitializedCameras] = useState(
    cameras !== undefined
  );

  useEffect(() => {
    if (!initializedCustomers) {
      dispatch(loadCustomers()).then(() => setInitializedCustomer(true));
    }
    if (!initializedCameras) {
      dispatch(loadCameras()).then(() => setInitializedCameras(true));
    }
  }, [initializedCustomers, initializedCameras]);

  useEffect(() => {
    if (!id || !cameras || !initializedCameras) return;
    const camera = find(propEq("id", Number(id)))(cameras);
    const newCamera = {
      ...camera,
      cameraConfig: pipe(toPairs)(camera.cameraConfig),
    };

    dispatch(initialize(FORMS.camerasForm, newCamera));
  }, [id, cameras, initializedCameras]);

  const removeVariableOnClick = () => {
    dispatch(arrayPop(FORMS.camerasForm, CAMERAS_FORM_FIELDS.cameraConfig));
  };
  const addVariableOnClick = () => {
    dispatch(
      arrayPush(FORMS.camerasForm, CAMERAS_FORM_FIELDS.cameraConfig, [])
    );
  };

  return (
    <Fragment>
      {initializedCustomers && initializedCameras && (
        <form className="-FormControl" onSubmit={handleSubmit}>
          <StackLayout space="2">
            <Field
              name={CAMERAS_FORM_FIELDS.name}
              component={TextInput}
              label={t("labels.name")}
              title={t("labels.name")}
              validate={[required]}
            />
            <StackLayout space="1">
              <div>{t("labels.cameraConfig")}</div>
              <ClusterLayout
                justify="space-between"
                noWrap
                space="2"
                overflowVisible
              >
                <div className="-widthStratch">
                  <button
                    className="-Button"
                    onClick={removeVariableOnClick}
                    title={t("labels.remove")}
                    type="button"
                  >
                    <i className="icon-minus"></i>
                  </button>
                </div>
                <div className="-widthStratch">
                  <button
                    className="-Button"
                    onClick={addVariableOnClick}
                    title={t("labels.add")}
                    type="button"
                  >
                    <i className="icon-plus"></i>
                  </button>
                </div>
              </ClusterLayout>
            </StackLayout>
            {cameraConfig?.map((camera, index) => (
              <ClusterLayout
                noWrap
                key={index}
                justify="center"
                space="2"
                overflowVisible
              >
                <div className="-widthStratch">
                  <Field
                    name={`${CAMERAS_FORM_FIELDS.cameraConfig}[${index}][0]`}
                    component={TextInput}
                    label={t("labels.key")}
                    title={t("labels.key")}
                    validate={[required, cameraConfigDuplicityKeyControl]}
                  />
                </div>
                <div className="-widthStratch">
                  <Field
                    name={`${CAMERAS_FORM_FIELDS.cameraConfig}[${index}][1]`}
                    component={TextInput}
                    label={t("labels.value")}
                    title={t("labels.value")}
                    validate={[required]}
                  />
                </div>
              </ClusterLayout>
            ))}
            <ClusterLayout space="2" align="center" noWrap>
              <Field
                name={CAMERAS_FORM_FIELDS.cameraEnabled}
                component={CheckBox}
                label={t("labels.cameraEnabled")}
                title={t("labels.cameraEnabled")}
              />
              <Field
                name={CAMERAS_FORM_FIELDS.alarmEnabled}
                component={CheckBox}
                label={t("labels.alarmEnabled")}
                title={t("labels.alarmEnabled")}
              />
            </ClusterLayout>
            <Field
              name={CAMERAS_FORM_FIELDS.belongsToCustomerId}
              component={SelectInput}
              inputValues={[
                { label: "", value: null },
                ...customers.map((customer) => ({
                  label: customer.name,
                  value: customer.id,
                })),
              ]}
              validate={[required]}
              label={t("labels.customer")}
              title={t("labels.customer")}
            />
            <Field
              name={CAMERAS_FORM_FIELDS.description}
              component={TextInput}
              label={t("labels.description")}
              title={t("labels.description")}
            />
            <div>
              <button
                className="-Button"
                title={t("labels.login")}
                type="submit"
              >
                {t("labels.save")}
              </button>
            </div>
          </StackLayout>
        </form>
      )}
    </Fragment>
  );
};

export default withReduxForm(CameraForm, {
  submitAction: addOrUpdateCamera,
  initialValues: cameraFormInitialValues,
  form: FORMS.camerasForm,
});
