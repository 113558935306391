import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getAlarm } from "../actions/alarmActions";
import MainNav from "../components/MainNav";
import ClusterLayout from "../components/ui/layouts/ClusterLayout";
import i18n from "../i18n";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import CameraForm from "../forms/CameraForm";
import AlarmCommentForm from "../forms/AlarmCommentForm";
import { closeModal, openModal } from "../actions/modalActions";
import { getModal } from "../selectors/modal";
import { modalNames } from "../constants";
import Alarm from "../components/Alarm";
import { initialAction } from "../actions/initiaAction";

export const PrivateLayout = ({ children }) => {
  useEffect(() => {
  }, []);

  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized) {
      dispatch(initialAction()).then(() => setInitialized(true));
    }
  }, [initialized]);

  return (
    initialized && (
      <div>
        {/* <Alarm /> */}
        <ClusterLayout noWrap justify="space-between">
          <MainNav />
          <div className="-flex1">
            <div className="-Page-body">{children}</div>
          </div>
        </ClusterLayout>
      </div>
    )
  );
};

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PrivateLayout;
