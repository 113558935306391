import axios from "axios";
import { apiUrl } from "../constants";

const { get, post, patch } = axios;

export const loadCameras = (header) => get(`${apiUrl}/cameras`, header);

export const addCamera = (data, header) =>
  post(`${apiUrl}/cameras`, data, header);

export const updateCamera = ({ id, ...data }, header) =>
  patch(`${apiUrl}/cameras/${id}`, data, header);
