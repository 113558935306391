import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { withReduxForm } from "../components/hocs/withReduxForm";
import StackLayout from "../components/ui/layouts/StackLayout";
import { FORMS, LOGIN_FORM_FIELDS } from "../constants";
import { required } from "../validations";
import { login } from "../actions/userActions";
import PasswordInput from "./inputs/PasswordInput";
import TextInput from "./inputs/TextInput";

const LoginForm = ({ handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <form className="-FormControl" onSubmit={handleSubmit}>
      <StackLayout space="2">
        <Field
          name={LOGIN_FORM_FIELDS.userName}
          type="text"
          component={TextInput}
          label={t("labels.userName")}
          title={t("labels.userName")}
          validate={[required]}
          autoFocus={true}
        />
        <Field
          name={LOGIN_FORM_FIELDS.password}
          type="password"
          component={PasswordInput}
          label={t("labels.password")}
          title={t("labels.password")}
          validate={[required]}
        />
        <div>
          <button className="-Button" title={t("labels.login")} type="submit">
            {t("labels.login")}
          </button>
        </div>
      </StackLayout>
    </form>
  );
};

export default withReduxForm(LoginForm, {
  submitAction: login,
  form: FORMS.loginForm,
});
