import { LOCALE } from "../constants";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en_us from "./en_us";
import cs_cz from "./cs_cz";

const translationHelper = (t) => ({
  translation: {
    ...t,
  },
});

const resources = {
  [LOCALE.CS_CZ]: translationHelper(cs_cz),
  [LOCALE.EN_US]: translationHelper(en_us),
};

i18n.use(initReactI18next).init({
  resources,
  lng: [LOCALE.CS_CZ],

  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
