import { createSelector } from "@reduxjs/toolkit";
import { CAMERAS_FORM_FIELDS, FORMS } from "../constants";
import { path } from "ramda";
import { getFormValues } from "redux-form";

export const cameraFormInitialValues = () => ({
  [CAMERAS_FORM_FIELDS.cameraEnabled]: false,
  [CAMERAS_FORM_FIELDS.alarmEnabled]: false,
});

export const cameraConfigSelector = createSelector(
  getFormValues(FORMS.camerasForm),
  path([CAMERAS_FORM_FIELDS.cameraConfig])
);
