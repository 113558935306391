import React from "react";
import PropTypes from "prop-types";
import FormFieldWrapper from "../FormFieldWrapper";

const Textarea = (prop) => {
  const { input, title, autoFocus = false } = prop;

  return (
    <FormFieldWrapper name={input.name} {...prop}>
      <textarea {...input} id={input.name} title={title} autoFocus={autoFocus} />
    </FormFieldWrapper>
  );
};

Textarea.propTypes = {
  input: PropTypes.object,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default Textarea;
