import React from "react";
import PropTypes from "prop-types";
import StackLayout from "../components/ui/layouts/StackLayout";
import { useTranslation } from "react-i18next";
import ClusterLayout from "../components/ui/layouts/ClusterLayout";

const FormFieldWrapper = ({
  name,
  label,
  children,
  meta: { touched, error },
}) => {
  const { t } = useTranslation();

  const errorCheck = (touched, error) => touched && error;

  if (children?.props?.type === "checkbox")
    return (
      <ClusterLayout space="1" align="center" noWrap>
        <div>{children}</div>
        <div>
          <label htmlFor={name}>{label}</label>
        </div>
      </ClusterLayout>
    );
  return (
    <StackLayout
      className="-FormGroup"
      data-variant={errorCheck(touched, error) && "error"}
      space="1"
    >
      <div>
        <label htmlFor={name}>{label}</label>
      </div>
      <div>{children}</div>
      <div>
        {errorCheck(touched, error) && (
          <span className="-FormGroup-validation">
            {t(`validations.${error}`)}
          </span>
        )}
      </div>
    </StackLayout>
  );
};

FormFieldWrapper.propTypes = {
  input: PropTypes.object,
  type: PropTypes.string,
  label: PropTypes.string,
};

export default FormFieldWrapper;
