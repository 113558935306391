import PropTypes from "prop-types";

const StackLayout = ({
  space,
  flexboxOff,
  spaceCollapseMd,
  spaceXsmd,
  className = "",
  children,
  ...rest
}) => {
  return (
    <div
      className={`-stackLayout ${className}`}
      data-flexbox={flexboxOff ? "false" : undefined}
      data-spacecollapse-md={spaceCollapseMd ? "true" : undefined}
      data-space-xsmd={spaceXsmd ? spaceXsmd : undefined}
      data-space={space ? space : undefined}
      {...rest}
    >
      {children}
    </div>
  );
};

StackLayout.propTypes = {
  flexboxOff: PropTypes.bool,
  spaceCollapseMd: PropTypes.bool,
  spaceXsmd: PropTypes.string,
  space: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default StackLayout;
