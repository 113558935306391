import PropTypes from "prop-types";

const BoxLayout = ({
  align,
  justify,
  space,
  spaceMd,
  className = "",
  children,
  ...rest
}) => {
  return (
    <div
      className={`-boxLayout ${className}`}
      data-align={align ? align : undefined}
      data-justify={justify ? justify : undefined}
      data-space={space ? space : undefined}
      data-space-md={spaceMd ? spaceMd : undefined}
      {...rest}
    >
      {children}
    </div>
  );
};

BoxLayout.propTypes = {
  align: PropTypes.string,
  justify: PropTypes.string,
  space: PropTypes.string,
  spaceMd: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default BoxLayout;
