import React from "react";

/**
 * Component to display a status text with an icon.
 *
 * @param text The text to display
 * @param status The status the text represents. Supported values are "info", "warning" and "error"
 * @returns {JSX.Element} The component
 * @constructor The component
 */
const StatusText = ({text, status}) => {
    const colors = {
        info: "blue",
        warning: "orange",
        error: "red",
    };

    const icons = {
        info: "icon-info",
        warning: "icon-warning",
        error: "icon-cancel-circle",
    };

    return (
        <span style={{color: colors[status]}}>
            <i className={icons[status]}></i> {text}
    </span>
    );
}

export default StatusText;