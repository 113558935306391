import axios from "axios";
import { apiUrl } from "../constants";

const { get, post, patch } = axios;


export const loadCustomers = (header) => get(`${apiUrl}/customers`, header);

export const addCustomer = (data, header) =>
  post(`${apiUrl}/customers`, data, header);

export const updateCustomer = ({ id, ...data }, header) =>
  patch(`${apiUrl}/customers/${id}`, data, header);