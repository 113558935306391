import axios from "axios";
import { apiUrl } from "../constants";

const { get, post, patch } = axios;

export const loadCompanies = (header) => get(`${apiUrl}/company`, header);

export const addCompany = (data, header) =>
  post(`${apiUrl}/company`, data, header);

export const updateCompany = ({ id, ...data }, header) =>
  patch(`${apiUrl}/company/${id}`, data, header);