import { find, propEq } from "ramda";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, initialize } from "redux-form";
import { withReduxForm } from "../components/hocs/withReduxForm";
import { CUSTOMER_FORM_FIELDS, dataSourceNames, FORMS } from "../constants";
import { getDataSource } from "../selectors/datasource";
import { required } from "../validations";
import TextInput from "./inputs/TextInput";
import StackLayout from "../components/ui/layouts/StackLayout";
import { useInit } from "../hooks";
import { addOrUpdateCustomer, loadCustomers } from "../actions/customerActions";

const CustomerForm = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const initialized = useInit(loadCustomers());

  const { id } = useParams();
  const customers = useSelector(getDataSource(dataSourceNames.CUSTOMERS));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id || !customers) return;
    const customer = find(propEq("id", Number(id)))(customers);
    console.log(customer);
    dispatch(initialize(FORMS.customer, customer));
  }, [id, customers]);

  return (
    <Fragment>
      {initialized && (
        <form className="-FormControl" onSubmit={handleSubmit}>
          <StackLayout space="2">
            <Field
              name={CUSTOMER_FORM_FIELDS.name}
              component={TextInput}
              label={t("labels.name")}
              title={t("labels.name")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.ico}
              component={TextInput}
              label={t("labels.ico")}
              title={t("labels.ico")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.contactPersonName}
              component={TextInput}
              label={t("labels.contactPerson")}
              title={t("labels.contactPerson")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.contactPersonPhone}
              component={TextInput}
              label={t("labels.phone")}
              title={t("labels.phone")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.discSizeLimit}
              component={TextInput}
              label={t("labels.discSizeLimit")}
              title={t("labels.discSizeLimit")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.limitRecordsPerDay}
              component={TextInput}
              label={t("labels.limitRecordsPerDay")}
              title={t("labels.limitRecordsPerDay")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.city}
              component={TextInput}
              label={t("labels.city")}
              title={t("labels.city")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.street}
              component={TextInput}
              label={t("labels.street")}
              title={t("labels.street")}
              validate={[required]}
            />
            <Field
              name={CUSTOMER_FORM_FIELDS.postCode}
              component={TextInput}
              label={t("labels.zipCode")}
              title={t("labels.zipCode")}
              validate={[required]}
            />
            <div>
              <button
                className="-Button"
                title={t("labels.save")}
                type="submit"
              >
                {t("labels.save")}
              </button>
            </div>
          </StackLayout>
        </form>
      )}
    </Fragment>
  );
};

export default withReduxForm(CustomerForm, {
  submitAction: addOrUpdateCustomer,
  form: FORMS.customer,
});
