import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./ui/blocks/Container";
import StackLayout from "./ui/layouts/StackLayout";
import CameraForm from "../forms/CameraForm";

const AddCamera = () => {
  const { t } = useTranslation();


  return (
    <Container>
      <StackLayout space="2">
        <div>
          <h1 className="-Heading" data-variant="h1">
            {t(`labels.addCamera`)}
          </h1>
        </div>
        <CameraForm />
      </StackLayout>
    </Container>
  );
};

export default AddCamera;
