import React from "react";
import PropTypes from "prop-types";
import FormFieldWrapper from "../FormFieldWrapper";

const SelectInput = (prop) => {
  const { input, title, multiple, inputValues, autoFocus = false } = prop;

  return (
    inputValues && (
      <FormFieldWrapper name={input.name} {...prop}>
        <select
          {...input}
          title={title}
          autoFocus={autoFocus}
          id={input.name}
          multiple={multiple}
        >
          {inputValues.map((option, key) => (
            <option key={key} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </FormFieldWrapper>
    )
  );
};

SelectInput.propTypes = {
  input: PropTypes.object,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default SelectInput;
