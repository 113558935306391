import { userResources } from "../resources";
import { setDataSource } from "./dataSourceActions";
import { dataSourceNames } from "../constants";
import { batchActions } from "redux-batched-actions";
import { header } from "../utils";

export const initialAction = () => (dispatch, getState) => {
  return new Promise((resolve, reject) =>
    Promise.all([userResources.loadMe(header(getState))])
      .then(([user]) => {
        dispatch(
          batchActions([setDataSource(dataSourceNames.USER, user.data)])
        );
        resolve();
      })
      .catch(reject)
  );
};
