import React from "react";
import { useTranslation } from "react-i18next";
import Container from "./ui/blocks/Container";
import CoverLayout from "./ui/layouts/CoverLayout";
import StackLayout from "./ui/layouts/StackLayout";

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="-Page-body">
      <CoverLayout>
        <div>
          <Container>
            <div>
              <StackLayout space="2">
                <div>
                  <h1 className="-Heading" data-variant="h1">
                    <i className="icon-baffled" />
                    &nbsp; {t("labels.notFoundHeading")}
                  </h1>
                </div>
                <div>{t("labels.notFoundFirstDescription")}</div>
                <div>{t("labels.notFoundSecondDescription")}</div>
              </StackLayout>
            </div>
          </Container>
        </div>
      </CoverLayout>
    </div>
  );
};

export default NotFound;
