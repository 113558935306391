import PropTypes from "prop-types";
import React from "react";
import { childElement } from "../../../utils";

const ClusterLayout = ({
  overflowVisible,
  noWrap,
  reverseDirectionMd,
  fragment,
  align,
  justify,
  space,
  className = "",
  children,
  ...rest
}) => {
  return (
    <div
      className={`-clusterLayout ${className}`}
      data-overflowvisible={overflowVisible ? "true" : undefined}
      data-nowrap={noWrap ? "true" : undefined}
      data-reversedirection-md={reverseDirectionMd ? "true" : undefined}
      data-align={align ? align : undefined}
      data-justify={justify ? justify : undefined}
      data-space={space ? space : undefined}
      {...rest}
    >
      {children.length ? childElement(children) : children}
    </div>
  );
};

ClusterLayout.propTypes = {
  overflowVisible: PropTypes.bool,
  noWrap: PropTypes.bool,
  reverseDirectionMd: PropTypes.bool,
  align: PropTypes.string,
  justify: PropTypes.string,
  space: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default ClusterLayout;
