import { toast } from "react-toastify";
import { PAGES_PATHS, dataSourceNames } from "../constants";
import historyObject from "../history";
import i18n from "../i18n";
import { cameraResources } from "../resources";
import { header, returnPath } from "../utils";
import { setDataSource } from "./dataSourceActions";

export const loadCameras = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    cameraResources
      .loadCameras(header(getState))
      .then((cameras) => {
        dispatch(setDataSource(dataSourceNames.CAMERAS, cameras.data));
        resolve();
      })
      .catch(reject);
  });
};

export const addOrUpdateCamera = (cameraData) => (dispatch, getState) => {
  const newCameraData = {
    ...cameraData,
    cameraConfig: Object.fromEntries(cameraData.cameraConfig || []),
  };
  delete newCameraData.passwordControl;
  delete newCameraData.customer;

  const resource = newCameraData.id
    ? cameraResources.updateCamera
    : cameraResources.addCamera;

  return new Promise((resolve, reject) => {
    resource(newCameraData, header(getState))
      .then((response) => {
        toast.success(i18n.t("labels.saved"));
        historyObject.push(returnPath(PAGES_PATHS.addUser));
        resolve();
      })
      .catch(reject);
  });
};
