import { find, propEq } from "ramda";
import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Field, initialize } from "redux-form";
import { withReduxForm } from "../components/hocs/withReduxForm";
import {
  COMPANY_FORM_FIELDS,
  COMPANY_STATE,
  dataSourceNames,
  FORMS,
} from "../constants";
import { getDataSource } from "../selectors/datasource";
import { required } from "../validations";
import TextInput from "./inputs/TextInput";
import StackLayout from "../components/ui/layouts/StackLayout";
import { useInit } from "../hooks";
import { addOrUpdateCompany, loadCompanies } from "../actions/companyActions";
import SelectInput from "./inputs/SelectInput";

const CompanyForm = ({ handleSubmit }) => {
  const { t } = useTranslation();

  const initialized = useInit(loadCompanies());

  const { id } = useParams();
  const companies = useSelector(getDataSource(dataSourceNames.COMPANIES));
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id || !companies) return;
    const company = find(propEq("id", Number(id)))(companies);
    console.log(company);
    dispatch(initialize(FORMS.company, company));
  }, [id, companies]);

  return (
    <Fragment>
      {initialized && (
        <form className="-FormControl" onSubmit={handleSubmit}>
          <StackLayout space="2">
            <Field
              name={COMPANY_FORM_FIELDS.state}
              component={SelectInput}
              validate={[required]}
              inputValues={[
                { label: "", value: null },
                ...COMPANY_STATE.map((state) => ({
                  label: state,
                  value: state,
                })),
              ]}
            />
            <Field
              name={COMPANY_FORM_FIELDS.name}
              component={TextInput}
              label={t("labels.name")}
              title={t("labels.name")}
              validate={[required]}
            />
            <Field
              name={COMPANY_FORM_FIELDS.ico}
              component={TextInput}
              label={t("labels.ico")}
              title={t("labels.ico")}
              validate={[required]}
            />
            <Field
              name={COMPANY_FORM_FIELDS.username}
              component={TextInput}
              label={t("labels.userName")}
              title={t("labels.userName")}
              validate={[required]}
            />
            <Field
              name={COMPANY_FORM_FIELDS.city}
              component={TextInput}
              label={t("labels.city")}
              title={t("labels.city")}
              validate={[required]}
            />
            <Field
              name={COMPANY_FORM_FIELDS.street}
              component={TextInput}
              label={t("labels.street")}
              title={t("labels.street")}
              validate={[required]}
            />
            <Field
              name={COMPANY_FORM_FIELDS.postCode}
              component={TextInput}
              label={t("labels.zipCode")}
              title={t("labels.zipCode")}
              validate={[required]}
            />
            <div>
              <button
                className="-Button"
                title={t("labels.save")}
                type="submit"
              >
                {t("labels.save")}
              </button>
            </div>
          </StackLayout>
        </form>
      )}
    </Fragment>
  );
};

export default withReduxForm(CompanyForm, {
  submitAction: addOrUpdateCompany,
  form: FORMS.company,
});
