import axios from "axios";
import { apiUrl } from "../constants";

const { get, post, patch } = axios;

export const getAlarm = ({ alarmId }, header) =>
  get(`${apiUrl}/alarm/${alarmId}`, header);

export const sendComment = ({ alarmId, text }, header) =>
  post(`${apiUrl}/alarm/${alarmId}/comments`, { text }, header);

export const loadAlarms = (header) => get(`${apiUrl}/alarm`, header);
