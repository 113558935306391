import React, { Fragment, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Field, autofill, initialize } from "redux-form";
import { withReduxForm } from "../components/hocs/withReduxForm";
import StackLayout from "../components/ui/layouts/StackLayout";
import { ALARM_COMMENT_FORM, alarmAnswers, FORMS } from "../constants";
import { required } from "../validations";
import {
  sendAlarmComment,
  transformAlarmCommentToLocaleStorage,
} from "../actions/alarmActions";
import Textarea from "./inputs/Textarea";
import { find, propEq } from "ramda";

const AlarmCommentForm = ({ handleSubmit, alarmId }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initialize(FORMS.alarmCommentForm, { alarmId }));
  }, []);

  const onCLickAnswer = (answer) => {
    //! zkontrolovat jestli by se nemel pouzit hook
    dispatch(autofill(FORMS.alarmCommentForm, ALARM_COMMENT_FORM.text, answer));
    return false;
  };

  return (
    <Fragment>
      <form className="-FormControl" onSubmit={handleSubmit}>
        <StackLayout space="2">
          <Field
            name={ALARM_COMMENT_FORM.text}
            component={Textarea}
            label={t("labels.comment")}
            title={t("labels.comment")}
            validate={[required]}
          />
          <StackLayout space="1">
            {alarmAnswers.map((value) => (
              <div key={value}>
                <button
                  className="-Button"
                  onClick={() => onCLickAnswer(t(`texts.answers.${value}`))}
                  title={t(`texts.answers.${value}`)}
                  type="button"
                >
                  {t(`texts.answers.${value}`)}
                </button>
              </div>
            ))}
          </StackLayout>
          <div>
            <button className="-Button" title={t("labels.login")} type="submit">
              {t("labels.save")}
            </button>
          </div>
        </StackLayout>
      </form>
    </Fragment>
  );
};

export default withReduxForm(AlarmCommentForm, {
  submitAction: sendAlarmComment,
  form: FORMS.alarmCommentForm,
});
