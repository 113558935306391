import { flattenObject } from "../utils";

export default flattenObject({
  labels: {
    logoDescription: "M2RS",
    logedIn: "Přihlášení proběhlo úspěšně",
    logedOut: "Odhlášení proběhlo úspěšně",
    logedInError: "Přihlášení se nepodařilo",
    login: "Přihlásit se",
    logOut: "Odhlásit se",
    loginDescription: "Použijte email a heslo k přihlášení do systému.",
    email: "Email",
    userName: "Uživatelské jméno",
    password: "Heslo",
    passwordControl: "Kontrola hesla",
    incorrectLogin: "Email nebo heslo není správné!",
    notFoundHeading: " Ach ne, chyba 404...",
    notFoundFirstDescription:
      " Je nám líto, ale požadovaná stránka nebyla nalezena.",
    notFoundSecondDescription:
      "Ujistěte se, že jste neudělali chybu v URL adrese. Je možné, že byla stránka přemístěna nebo odstraněna.",
    users: "Uživatelé",
    addUsers: "Přidat uživatele",
    save: "Uložit",
    role: "Role",
    customer: "Zákazník",
    id: "ID",
    state: "Stav",
    saved: "Uloženo",
    cameras: "Kamery",
    camera: "Kamera",
    name: "Název",
    cameraEnabled: "Povolit kameru",
    alarmEnabled: "Povolit alarmy",
    description: "Popis",
    addCamera: "Přidat kameru",
    comment: "Komentář",
    key: "Klíč",
    value: "Hodnota",
    cameraConfig: "Konfigurace kamery",
    add: "Přidat",
    remove: "Odebrat",
    yoloClasses: "YOLO třídy",
    createdOn: "Vytvořeno",
    version: "Verze",
    alarmState: "Stav alarmu",
    alarmType: "Typ alarmu",
    dateAdd: "Čas přidání",
    dateAdateLastEditdd: "Čas poslední aktualizace",
    recordPath: "Nahrávka",
    alarms: "Alarmy",
    ico: "Ičo",
    adress: "Adresa",
    discSizeLimit: "Limit disku",
    limitRecordsPerDay: "Limit nahrávek",
    contactPerson: "Kontaktní osoba",
    customers: "Zákazníci",
    addCustomer: "Přidat zákazníka",
    phone: "Telefon",
    city: "Město",
    street: "Ulice",
    zipCode: "PSČ",
    companies: "Společnosti",
    addCompany: "Přidat společnost",
    kubernetes: {
      heading: "Kubernetes",
      component: "Komponenta",
      status: "Stav",
      ready: "Kubernetes pracuje správně",
      notReady: "Kubernetes není v pořádku - viz tabulka níže",
      deployment: "Deployment",
      namespace: "Namespace",
      deployedOn: "Nasazeno",
      implementation: "Použitá implementace KubernetesService",
      running: "Běžící  deploymenty",
      orphans: "Potracenky TODO vymyslet lepší název",
    },
  },
  mainNavItems: {
    administration: "Administrace",
    adminPage: "Administrační stránka",
    connectors: "Konektory",
    userRoles: "Uživatelské role",
    YOLOClasses: "YOLO třídy",
    users: "Uživatelé",
    cameras: "Kamery",
    customers: "Zákazníci",
    sources: "Zdroje",
    clientPage: "Klientská část",
    videoStreams: "Video streamy",
    availableCameras: "Dostupné kamery",
    companies: "Společnosti",
    arrivalsAndDepartures: "Příjezdy a odjezdy",
    detectedLpn: "Detekované spz",
    detectedEvents: "Detekované události",
    alarms: "Alarmy",
    videoPlayback: "Video playback",
    reports: "Reporty",
    kubernetes: "Kubernetes",
  },
  validations: {
    required: "Toto pole je povinné!",
    invalidEmail: "Email není správně!",
    passwordsDoNotMatch: "Hesla se musí shodovat!",
    duplicateCameraConfigKey: "Duplicitní klíč!",
  },
  enums: {
    userStatus: {
      ok: "OK",
      banned: "Zablokován",
    },
    status: {
      on: "Zapnuto",
      off: "Vypnuto",
    },
    alarmType: {
      HUMAN: "Člověk",
      SPZ: "Auto",
    },
    roles: {
      ROLE_ADMIN: "Administrátor",
      ROLE_WEB: "Web",
      ROLE_OPERATOR: "Operátor",
      ROLE_CUSTOMER: "Zákazník",
      ROLE_SUPERVISOR: "Supervizor",
      ROLE_DOORMAN: "Vrátný",
    },
  },
  texts: {
    alarm: "{{type}} na kameře {{camera}}!",
    answers: {
      0: "Falešný poplach",
      1: "Jednotka vyslána",
    },
  },
});
