import React from "react";
import { useTranslation } from "react-i18next";
import { Field } from "redux-form";
import { withReduxForm } from "../components/hocs/withReduxForm";
import StackLayout from "../components/ui/layouts/StackLayout";
import { FORMS, LOGIN_FORM_FIELDS, YOLO_CLASSES_FORM_FIELDS } from "../constants";
import { required } from "../validations";
import { login } from "../actions/userActions";
import PasswordInput from "./inputs/PasswordInput";
import TextInput from "./inputs/TextInput";
import FileInput from "./inputs/FileInput";

const YoloClassesForm = ({ handleSubmit }) => {
  const { t } = useTranslation();

  return (
    <form className="-FormControl" onSubmit={handleSubmit}>
      <StackLayout space="2">
        {/* <Field
          name={YOLO_CLASSES_FORM_FIELDS.classesFile}
          component={FileInput}
          label={t("labels.userName")}
          title={t("labels.userName")}
          validate={[required]}
        /> */}
        {/* <Field name="attachment" component="input" type="file"/> */}
        <div>
          <button className="-Button" title={t("labels.login")} type="submit">
            {t("labels.save")}
          </button>
        </div>
      </StackLayout>
    </form>
  );
};

export default withReduxForm(YoloClassesForm, {
  // submitAction: login,
  form: FORMS.yoloClasses,
});
