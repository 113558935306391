import AppLayout from "./AppLayout";
import { Fragment, useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import ScrollToTop from "../components/ScrollToTop";
import { unstable_HistoryRouter as HistoryRouter } from "react-router-dom";
import historyObject from "../history";
import { useDispatch } from "react-redux";
import { initialAction } from "../actions/initiaAction";

function App() {
  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);
        // dispatch(initialAction()).then(() => setInitialized(true))
    }
  }, [initialized]);


  return (
    <Fragment>
      {initialized && (
        <HistoryRouter history={historyObject}>
          <ScrollToTop />
          <AppLayout />
          <ToastContainer autoClose={5000} theme="colored"/>
        </HistoryRouter>
      )}
    </Fragment>
  );
}

export default App;
