import React from "react";
import PropTypes from "prop-types";
import FormFieldWrapper from "../FormFieldWrapper";

const CheckBox = (prop) => {
  const { input, title, autoFocus = false } = prop;

  return (
    <FormFieldWrapper name={input.name} {...prop}>
      <input {...input} title={title} id={input.name} checked={input.value} autoFocus={autoFocus} type="checkbox" />
    </FormFieldWrapper>
  );
};

CheckBox.propTypes = {
  input: PropTypes.object,
  title: PropTypes.string,
  autoFocus: PropTypes.bool,
};

export default CheckBox;
