import { toast } from "react-toastify";
import { PAGES_PATHS, dataSourceNames } from "../constants";
import { companyResources } from "../resources";
import { header, returnPath } from "../utils";
import { setDataSource } from "./dataSourceActions";
import historyObject from "../history";
import i18n from "../i18n";

export const loadCompanies = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    companyResources
      .loadCompanies(header(getState))
      .then((companies) => {
        dispatch(setDataSource(dataSourceNames.COMPANIES, companies.data));
        resolve();
      })
      .catch(reject);
  });
};

export const addOrUpdateCompany= (companyData) => (dispatch, getState) => {

  const resource = companyData.id
    ? companyResources.updateCompany
    : companyResources.addCompany;

  return new Promise((resolve, reject) => {
    resource(companyData, header(getState))
      .then((response) => {
        toast.success(i18n.t("labels.saved"));
        historyObject.push(returnPath(PAGES_PATHS.companies));
        resolve();
      })
      .catch(reject);
  });
};
