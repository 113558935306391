import {header} from "../utils";
import {setDataSource} from "./dataSourceActions";
import {dataSourceNames} from "../constants";
import {
    loadKubernetesComponentsStatus,
    loadKubernetesDeployments, loadKubernetesImplementation,
    loadKubernetesOrphans
} from "../resources/kubernetesResources";

export const loadKubernetesComponentsStatusAction = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        loadKubernetesComponentsStatus(header(getState))
            .then((kubernetesComponentsStatus) => {
                dispatch(setDataSource(dataSourceNames.KUBERNETES.STATUS, kubernetesComponentsStatus.data));
                resolve();
            })
            .catch(reject);
    });
};

export const loadKubernetesDeploymentsAction = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        loadKubernetesDeployments(header(getState))
            .then((response) => {
                dispatch(setDataSource(dataSourceNames.KUBERNETES.DEPLOYMENTS, response.data));
                resolve();
            })
            .catch(reject);
    });
}

export const loadKubernetesOrphansAction = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        loadKubernetesOrphans(header(getState))
            .then((response) => {
                dispatch(setDataSource(dataSourceNames.KUBERNETES.ORPHANS, response.data));
                resolve();
            })
            .catch(reject);
    });
}

export const loadKubernetesImplementationAction = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        loadKubernetesImplementation(header(getState))
            .then((response) => {
                dispatch(setDataSource(dataSourceNames.KUBERNETES.IMPLEMENTATION, response.data));
                resolve();
            })
            .catch(reject);
    });
}