import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "./ui/blocks/Container";
import StackLayout from "./ui/layouts/StackLayout";
import ClusterLayout from "./ui/layouts/ClusterLayout";
import { Link } from "react-router-dom";
import { dataSourceNames, PAGES_PATHS } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { getDataSource } from "../selectors/datasource";
import { join } from "ramda";
import { loadCameras } from "../actions/cameraActions";
import { returnPath } from "../utils";

const Cameras = (props) => {
  const { t } = useTranslation();

  const [initialized, setInitialized] = useState(false);
  const dispatch = useDispatch();

  const cameras = useSelector(getDataSource(dataSourceNames.CAMERAS));

  useEffect(() => {
    if (!initialized) {
      dispatch(loadCameras()).then(() => setInitialized(true));
    }
  }, [initialized]);

  return (
    <Container>
      <StackLayout space="2">
        <ClusterLayout justify="space-between" align="center">
          <div>
            <h1 className="-Heading" data-variant="h1">
              {t(`labels.cameras`)}
            </h1>
          </div>
          <div>
            <Link
              to={returnPath(PAGES_PATHS.addCamera)}
              className="-Button"
              data-type="icon"
            >
              <i className="icon-plus"></i>
            </Link>
          </div>
        </ClusterLayout>
        {initialized && (
          <div>
            <table className="-Table">
              <thead>
                <tr>
                  <th>{t(`labels.id`)}</th>
                  <th>{t(`labels.name`)}</th>
                  <th>{t(`labels.rtspUrl`)}</th>
                  <th>{t(`labels.cameraEnabled`)}</th>
                  <th>{t(`labels.alarmEnabled`)}</th>
                  <th>{t(`labels.description`)}</th>
                  <th className="-widthZero"></th>
                </tr>
              </thead>
              <tbody>
                {cameras.map((value, index) => (
                  <tr key={index}>
                    <td>{value.id}</td>
                    <td>{value.name}</td>
                    <td>{value.rtspUrl}</td>
                    <td>
                      {value.cameraEnabled
                        ? t(`enums.status.on`)
                        : t(`enums.status.off`)}
                    </td>
                    <td>
                      {value.alarmEnabled
                        ? t(`enums.status.on`)
                        : t(`enums.status.off`)}
                    </td>
                    <td>{value.description}</td>
                    <td className="-widthZero">
                      <Link
                        to={`${returnPath(PAGES_PATHS.addCamera)}/${value.id}`}
                        className="-Button"
                        data-type="icon"
                      >
                        <i className="icon-pencil"></i>
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </StackLayout>
    </Container>
  );
};

export default Cameras;
