import PropTypes from "prop-types";


const Container = ({ width, children }) => {
  return <div className="-Container"
  data-colspan={width}>{children}</div>;
};

Container.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default Container;
