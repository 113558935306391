import Container from "./ui/blocks/Container";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getDataSource} from "../selectors/datasource";
import {dataSourceNames} from "../constants";
import {
    loadKubernetesComponentsStatusAction,
    loadKubernetesDeploymentsAction, loadKubernetesImplementationAction,
    loadKubernetesOrphansAction
} from "../actions/kubernetesActions";
import StackLayout from "./ui/layouts/StackLayout";
import ClusterLayout from "./ui/layouts/ClusterLayout";
import StatusText from "./StatusText";

const Kubernetes = () => {

    const {t} = useTranslation();

    const [componentsStatusInitialized, setComponentsStatusInitialized] = useState(false);
    const [deploymentsInitialized, setDeploymentsInitialized] = useState(false);
    const [orphansInitialized, setOrphansInitialized] = useState(false);
    const [implementationInitialized, setImplementationInitialized] = useState(false);
    const dispatch = useDispatch();

    const kubernetesStatus = useSelector(getDataSource(dataSourceNames.KUBERNETES.STATUS));
    const kubernetesDeployments = useSelector(getDataSource(dataSourceNames.KUBERNETES.DEPLOYMENTS));
    const kubernetesOrphans = useSelector(getDataSource(dataSourceNames.KUBERNETES.ORPHANS));
    const kubernetesImplementation = useSelector(getDataSource(dataSourceNames.KUBERNETES.IMPLEMENTATION));

    // maybe each of these should be a separate component?
    useEffect(() => {
        if (!componentsStatusInitialized) {
            dispatch(loadKubernetesComponentsStatusAction()).then(() => setComponentsStatusInitialized(true));
        }
    }, [dispatch, componentsStatusInitialized]);

    useEffect(() => {
        if (!deploymentsInitialized) {
            dispatch(loadKubernetesDeploymentsAction()).then(() => setDeploymentsInitialized(true));
        }
    }, [dispatch, deploymentsInitialized]);

    useEffect(() => {
        if (!orphansInitialized) {
            dispatch(loadKubernetesOrphansAction()).then(() => setOrphansInitialized(true));
        }
    }, [dispatch, orphansInitialized]);

    useEffect(() => {
        if (!implementationInitialized) {
            dispatch(loadKubernetesImplementationAction()).then(() => setImplementationInitialized(true));
        }
    }, [dispatch, implementationInitialized]);


    return (
        <Container>
            <StackLayout space="2">
                <ClusterLayout justify="space-between" align="center">
                    <div>
                        <h1 className="-Heading" data-variant="h1">
                            {t(`labels.kubernetes.heading`)}
                        </h1>
                    </div>
                </ClusterLayout>
                {implementationInitialized && (
                    <div>
                        <span>{t(`labels.kubernetes.implementation`)}: <b>{kubernetesImplementation.value}</b></span>
                    </div>
                )}
                {componentsStatusInitialized && (
                    <div>
                        <StackLayout space="1">
                            <StatusText
                                text={kubernetesStatus.isKubernetesReady ? t(`labels.kubernetes.ready`) : t(`labels.kubernetes.notReady`)}
                                status={kubernetesStatus.isKubernetesReady ? "info" : "warning"}/>
                            <table className="-Table">
                                <thead>
                                <tr>
                                    <th>{t(`labels.kubernetes.component`)}</th>
                                    <th>{t(`labels.kubernetes.status`)}</th>
                                </tr>
                                </thead>
                                <tbody>
                                {Object.keys(kubernetesStatus.componentsStatus).map((key, index) => (
                                    <tr key={index}>
                                        <td>{key}</td>
                                        <td><StatusText text={kubernetesStatus.componentsStatus[key]}
                                                        status={"READY" !== kubernetesStatus.componentsStatus[key] ? "warning" : "info"}/>
                                        </td>
                                    </tr>
                                ))}
                                </tbody>
                            </table>
                        </StackLayout>
                    </div>
                )}
                <hr/>
                {orphansInitialized && (
                    <div>
                        <span>{t(`labels.kubernetes.orphans`)}</span>
                        <table className="-Table">
                            <thead>
                            <tr>
                                <th>{t(`labels.id`)} {t(`labels.cameras`)}</th>
                                <th>{t(`labels.kubernetes.deployment`)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {kubernetesOrphans.map((orphan, index) => (
                                <tr key={index}>
                                    <td>{orphan.cameraId}</td>
                                    <td>{orphan.deploymentName}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <hr/>
                {deploymentsInitialized && (
                    <div>
                        <span>{t(`labels.kubernetes.running`)} - FIX NPE, pythonDeployment může být null a pak se to celý vysype</span>
                        <table className="-Table">
                            <thead>
                            <tr>
                                <th>{t(`labels.id`)}</th>
                                <th>{t(`labels.id`)} {t(`labels.cameras`)}</th>
                                <th>{t(`labels.kubernetes.deployment`)}</th>
                                <th>{t(`labels.kubernetes.status`)}</th>
                                <th>{t(`labels.createdOn`)}</th>
                                <th>{t(`labels.kubernetes.deployedOn`)}</th>
                            </tr>
                            </thead>
                            <tbody>
                            {kubernetesDeployments.map((deployment, index) => (
                                <tr key={index}>
                                    <td>{deployment.pythonDeployment.id}</td>
                                    <td>{deployment.pythonDeployment.cameraId}</td>
                                    <td>{deployment.deploymentName}</td>
                                    <td><StatusText text={deployment.pythonDeployment.status}
                                                    status={"DEPLOYED" !== deployment.pythonDeployment.status ? "warning" : "info"}/>
                                    </td>
                                    <td>{deployment.pythonDeployment.createdOn}</td>
                                    <td>{deployment.pythonDeployment.deployedOn}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </StackLayout>
        </Container>
    )
}

export default Kubernetes;