import { toast } from "react-toastify";
import { PAGES_PATHS, dataSourceNames } from "../constants";
import { customerResources } from "../resources";
import { header, returnPath } from "../utils";
import { setDataSource } from "./dataSourceActions";
import historyObject from "../history";
import i18n from "../i18n";


export const loadCustomers = () => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
      customerResources
        .loadCustomers(header(getState))
        .then((customers) => {
          dispatch(setDataSource(dataSourceNames.CUSTOMERS, customers.data));
          resolve();
        })
        .catch(reject);
    });
  };

  export const addOrUpdateCustomer = (customerData) => (dispatch, getState) => {

    const resource = customerData.id
      ? customerResources.updateCustomer
      : customerResources.addCustomer;
  
    return new Promise((resolve, reject) => {
      resource(customerData, header(getState))
        .then((response) => {
          toast.success(i18n.t("labels.saved"));
          historyObject.push(returnPath(PAGES_PATHS.customers));
          resolve();
        })
        .catch(reject);
    });
  };
  