import { configureStore } from "@reduxjs/toolkit";
import { batchDispatchMiddleware } from "redux-batched-actions";
import thunk from "redux-thunk";
import { dataSourceNames, modalNames } from "./constants";
import { rootReducer } from "./reducers/rootReducer";

const configureReduxStore = (
  reducer,
  preloadedState = {},
  thunkExtraArgument
) => {
  const thunkMiddleware = thunkExtraArgument
    ? thunk.withExtraArgument(thunkExtraArgument)
    : thunk;

  const middleWares = [thunkMiddleware, batchDispatchMiddleware];

  return configureStore({
    reducer,
    preloadedState,
    middleware: middleWares,
  });
};

export default configureReduxStore(rootReducer, {
  modal: {
    [modalNames.ALARM_COMMENT_MODAL]: false,
  },
  dataSource: {
    [dataSourceNames.USER]: {},
    [dataSourceNames.ACCESS_CREDENTIALS]: {},
    [dataSourceNames.USERS]: [],
    [dataSourceNames.KUBERNETES.STATUS]: {},
    [dataSourceNames.KUBERNETES.DEPLOYMENTS]: {},
    [dataSourceNames.KUBERNETES.ORPHANS]: {},
    [dataSourceNames.KUBERNETES.IMPLEMENTATION]: {},
    [dataSourceNames.ALARMS]: [],
    [dataSourceNames.COMPANIES]: [],
    [dataSourceNames.CUSTOMERS]: [],
  },
});
