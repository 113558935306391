import keyMirror from "key-mirror";
import { range } from "ramda";
import { returnPath } from "./utils";

export const appUrl =
  window.__env__.REACT_APP_APP_URL || "http://localhost:3000/";
export const dataPath = appUrl;
export const apiUrl =
  window.__env__.REACT_APP_API_URL || "http://localhost:8080";
export const webSocketUrl =
  window.__env__.REACT_APP_WEBSOCKET_URL || "ws://localhost:8080/stomp";

export const WS_ALARM_QUEUE = "/queue/alarm/";

export const datasourceTypes = {
  SET: "@datasource/SET",
  CLEAR: "@datasource/CLEAR",
};

export const modalTypes = {
  OPEN: "@modal/OPEN",
  CLOSE: "@modal/CLOSE",
};

export const LOCALE = {
  EN_US: "en-US",
  CS_CZ: "cs-CZ",
};

export const ROLES = keyMirror({
  ROLE_ADMIN: null,
  ROLE_WEB: null,
  ROLE_OPERATOR: null,
  ROLE_CUSTOMER: null,
  ROLE_SUPERVISOR: null,
  ROLE_DOORMAN: null,
});

export const FORMS = keyMirror({
  loginForm: null,
  userForm: null,
  company: null,
  camerasForm: null,
  alarmCommentForm: null,
  yoloClasses: null,
  customer: null,
});

export const LOGIN_FORM_FIELDS = keyMirror({
  userName: null,
  password: null,
});

export const USERS_FORM_FIELDS = keyMirror({
  userName: null,
  password: null,
  passwordControl: null,
  authorities: null,
  belongsToCustomerId: null,
});

export const CAMERAS_FORM_FIELDS = keyMirror({
  name: null,
  cameraConfig: null,
  belongsToCustomerId: null,
  cameraEnabled: null,
  alarmEnabled: null,
  description: null,
});

export const COMPANY_FORM_FIELDS = keyMirror({
  state: null,
  name: null,
  ico: null,
  username: null,
  city: null,
  street: null,
  postCode: null,
});

export const CUSTOMER_FORM_FIELDS = keyMirror({
  name: null,
  ico: null,
  contactPersonName: null,
  contactPersonPhone: null,
  discSizeLimit: null,
  limitRecordsPerDay: null,
  city: null,
  street: null,
  postCode: null,
});

export const YOLO_CLASSES_FORM_FIELDS = keyMirror({
  version: null,
});

export const ADDRESS_FORM_FIELDS = keyMirror({
  dateAdded: null,
  city: null,
  postCode: null,
});

export const ALARM_COMMENT_FORM = keyMirror({
  text: null,
});

export const dataSourceNames = Object.freeze({
  USER: "user",
  USERS: "users",
  ACCESS_CREDENTIALS: "accessCredentials",
  CAMERAS: "cameras",
  CUSTOMERS: "customers",
  LAST_ALARM: "lastAlarm",
  ALARMS: "alarms",
  COMPANIES: "companies",
  KUBERNETES: {
    STATUS: "kubernetesStatus",
    DEPLOYMENTS: "kubernetesDeployments",
    ORPHANS: "kubernetesOrphans",
    IMPLEMENTATION: "kubernetesImplementation",
  },
});

export const PAGES_PATHS = {
  home: {
    path: "/",
  },
  users: {
    path: "/users",
  },
  addUser: {
    path: "/users/add-user",
  },
  cameras: {
    path: "/cameras",
  },
  addCamera: {
    path: "/add-camera",
  },
  yoloClasses: {
    path: "/yolo-classes",
  },
  kubernetes: {
    path: "/kubernetes",
  },
  alarms: {
    path: "/alarms",
  },
  companies: {
    path: "/companies",
  },
  addCompany: {
    path: "/add-company",
  },
  customers: {
    path: "/customers",
  },
  addCustomer: {
    path: "/add-customer",
  },
};

export const alarmAnswers = range(0, 2);

export const modalNames = Object.freeze({
  ALARM_COMMENT_MODAL: "alarmCommentModal",
});

export const NAV_ITEMS = [
  {
    authorities: [ROLES.ROLE_ADMIN],
    icon: "wrench",
    label: "administration",
    children: [
      {
        path: "/", //admin-page
        icon: "home",
        label: "adminPage",
      },
      {
        path: returnPath(PAGES_PATHS.users),
        icon: "user",
        label: "users",
      },
      {
        path: returnPath(PAGES_PATHS.cameras),
        icon: "camera",
        label: "cameras",
      },
      {
        path: returnPath(PAGES_PATHS.yoloClasses),
        icon: "pacman",
        label: "YOLOClasses",
      },
      {
        path: returnPath(PAGES_PATHS.kubernetes),
        icon: "cog",
        label: "kubernetes",
      },
    ],
  },
  {
    authorities: [ROLES.ROLE_WEB],
    icon: "user-tie",
    label: "clientPage",
    children: [
      {
        path: "/video-streams",
        icon: "play2",
        label: "videoStreams",
      },
      {
        path: "/available-cameras",
        icon: "video-camera",
        label: "availableCameras",
      },
      {
        path: returnPath(PAGES_PATHS.companies),
        icon: "list",
        label: "companies",
      },
      {
        path: "/arrivals-and-departures",
        icon: "road",
        label: "arrivalsAndDepartures",
      },
      {
        path: "/detected-lpn",
        icon: "truck",
        label: "detectedLpn",
      },
      {
        path: "/detected-events",
        icon: "calendar",
        label: "detectedEvents",
      },
      {
        path: returnPath(PAGES_PATHS.alarms),
        icon: "warning",
        label: "alarms",
      },
      {
        path: returnPath(PAGES_PATHS.customers),
        icon: "trophy",
        label: "customers",
      },
      {
        path: "/video-playback",
        icon: "film",
        label: "videoPlayback",
      },
      {
        path: "/reports",
        icon: "folder-open",
        label: "reports",
      },
    ],
  },
];

export const COMPANY_STATE = ["ACTIVE", "INACTIVE"];
