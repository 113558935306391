import PropTypes from "prop-types";

const CenterLayout = ({
  justifyMdOff,
  contentsWidth,
  className = "",
  children,
  ...rest
}) => {
  return (
    <div
      className={`-centerLayout ${className}`}
      data-justify-md={justifyMdOff ? "false" : undefined}
      data-contentswidth={contentsWidth ? contentsWidth : undefined}
      {...rest}
    >
      {children}
    </div>
  );
};

CenterLayout.propTypes = {
  justifyMdOff: PropTypes.bool,
  contentsWidth: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default CenterLayout;
