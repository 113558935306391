import { equals, filter, head, length, pipe } from "ramda";

export const required = (value) =>
  value || typeof value === "number" ? undefined : "required";

export const maxLength = (max) => (value) =>
  value && value.length > max ? `Must be ${max} characters or less` : undefined;

export const minLength = (min) => (value) =>
  value && value.length < min ? `Must be ${min} characters or more` : undefined;

export const number = (value) =>
  value && isNaN(Number(value)) ? "Must be a number" : undefined;

export const email = (value) =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? "invalidEmail"
    : undefined;

export const passwordsMustMatch = (value, allValues) =>
  value !== allValues.password ? "passwordsDoNotMatch" : undefined;

export const cameraConfigDuplicityKeyControl = (value, allValues) =>
  pipe(filter(pipe(head, equals(value))), length)(allValues.cameraConfig) > 1
    ? "duplicateCameraConfigKey"
    : undefined;
